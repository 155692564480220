<!-- 注册 -->
<template>
    <div class="register">
        <el-row>
            <el-col :span="24">
                <div class="top">
                    中国美术学院社会美术水平考试：机构报名管理系统
                </div>

            </el-col>
            <el-col :span="24">
                <div class="content">
                    <div class="content_c">
                        <div class="jigouReg">
                            <div class="Reg">培训机构注册</div>
                            <div class="have" @click="exist">已有账号，马上去登录</div>
                        </div>
                        <!-- 表单 -->
                        <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="120px">
                            <el-form-item label="机构名称" prop="name">
                                <el-input v-model="form.name" placeholder="请输入机构名称"></el-input>
                            </el-form-item>
                            <el-form-item label="详细地址" prop="address">
                                <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
                            </el-form-item>
                            <el-form-item label="负责人" prop="duty">
                                <el-input v-model="form.duty" placeholder="请输入负责人"></el-input>
                            </el-form-item>
                            <el-form-item label="移动电话" prop="mobilePhone">
                                <el-input v-model="form.mobilePhone" placeholder="请输入移动电话"></el-input>
                            </el-form-item>
                            <el-form-item label="网址">
                                <el-input v-model="form.website" placeholder="请输入网址"></el-input>
                            </el-form-item>
                            <el-form-item label="统一信用代码" prop="creditCode">
                                <el-input v-model="form.creditCode" placeholder="请输入统一信用代码"></el-input>
                            </el-form-item>
                            <el-form-item label="教学点数量" prop="teachingNum">
                                <el-input v-model="form.teachingNum" placeholder="请输入教学点数量"></el-input>
                            </el-form-item>
                            <el-form-item label="教室数量" prop="roomNum">
                                <el-input v-model="form.roomNum" placeholder="请输入教室数量"></el-input>
                            </el-form-item>
                            <el-form-item label="学生人数" prop="studentNum">
                                <el-input v-model="form.studentNum" placeholder="请输入学生人数"></el-input>
                            </el-form-item>
                            <el-form-item label="员工人数" prop="staffNum">
                                <el-input v-model="form.staffNum" placeholder="请输入员工人数"></el-input>
                            </el-form-item>
                            <el-form-item label="考级联系人">
                                <el-input v-model="form.contacts" placeholder="请输入考级联系人"></el-input>
                            </el-form-item>
                            <el-form-item label="考级联系人电话">
                                <el-input v-model="form.telephone" placeholder="请输入考级联系人电话"></el-input>
                            </el-form-item>
                            <el-form-item label="微信">
                                <el-input v-model="form.WeChat" placeholder="请输入微信"></el-input>
                            </el-form-item>
                            <el-form-item label="QQ">
                                <el-input v-model="form.QQ" placeholder="请输入QQ"></el-input>
                            </el-form-item>
                           <div class="submit">
                                <button class="butn" type="button" @click="submit('form')">提交资料</button> 
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name:"",//机构名称
                    address:"",//详细地址
                    duty:"",//负责人
                    mobilePhone:"",//移动电话
                    website:"",//网址
                    creditCode:"",//统一信用代码
                    teachingNum:"",//教学点数量
                    roomNum:"",//教室数量
                    studentNum:"",//学生人数
                    staffNum:"",//员工人数
                    contacts:"",//考级联系人
                    telephone:"",//考级联系人电话
                    WeChat:"",//微信
                    QQ:""//QQ
                },
                rules: {
                    name:[
                        {required: true,message: '机构名称不能为空',trigger: 'blur'},
                    ],
                    address:[
                        {required: true,message: '详细地址不能为空',trigger: 'blur'},
                    ],
                    duty:[
                        {required: true,message: '负责人不能为空',trigger: 'blur'},
                    ],
                    mobilePhone:[
                        {required: true,message: '移动电话不能为空',trigger: 'blur'},
                       {
                         pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                         message: '移动电话有误', 
                         trigger: 'blur' ,
                       }
                        
                    ],
                    creditCode:[
                        { 
                            pattern:/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/,
                            message: '统一信用代码有误', 
                            trigger: 'blur' ,
                     }],
                    teachingNum:[
                        {required: true,message: '教学点数量不能为空',trigger: 'blur'},
                    ],
                    roomNum:[
                        {required: true,message: '教室数量不能为空',trigger: 'blur'},
                    ],
                    studentNum:[
                        {required: true,message: '学生人数不能为空',trigger: 'blur'},
                    ],
                    staffNum:[
                        {required: true,message: '员工人数不能为空',trigger: 'blur'},
                    ]
                },
                bool:false
            }
        },
        created() {

        },
        methods: {
            submit(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        this.$request({url:'/api/mechanismexam/infoAdd',method:'POST',data:{
                            name:this.form.name,    //机构名称
                            address:this.form.address,   //机构地址
                            mobile:this.form.mobilePhone,    //机构电话
                            mechanism_web:this.form.website, //机构网址
                            ucc:this.form.creditCode,   //统一信用代码
                            teaching_point:this.form.teachingNum,    //教学点总量
                            classroom:this.form.roomNum, //教室总量
                            students_num:this.form.studentNum,  //学生人数
                            staff_num:this.form.staffNum, //员工人数
                            qq:this.form.QQ,    //QQ
                            wx:this.form.WeChat,    //微信
                            contacts:this.form.contacts,  //考级联系人
                            contacts_number:this.form.telephone,   //考级联系人电话
                            principal:this.form.duty //机构负责人
                        }}).then(res=>{
                            console.log(res)
                            if(res.code==1){
                                this.$message({
                                    message: '注册成功',
                                    type: 'success'
                                })
                                this.$router.push({
                                    path: "/"
                                })
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        })
                    } else {
                      console.log('error submit!!');
                      return false;
                    }
                });
            },
            exist(){//跳转登录页面
                this.$router.push({
                    path: "/",
                })
            }
        }
    }
</script>

<style scoped="scoped">
     @media screen and (min-width:320px) {
        .top {
            font-size: 13px !important;
            height: 30px !important;
            line-height: 30px !important;
        }
        .submit{
            width: 300px !important;
        }
    }

    @media screen and (min-width:375px) {
        .top {
            font-size: 15px !important;
            height: 40px !important;
            line-height: 40px !important;
        }

        .content_c {
            width: 350px !important;
        }
        .submit{
            width: 380px !important;
        }
        .butn{
            width: 200px !important;
        }
    }

    @media screen and (min-width:768px) {
        .top {
            font-size: 18px !important;
            height: 50px !important;
            line-height: 50px !important;
        }

       .submit{
           width: 500px !important;
       }
       .butn{
           width: 300px !important;
       }
        .content_c {
            width: 500px !important;
            /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important; */
        }

    }

    @media screen and (min-width:1024px) {
        .top {
            font-size: 28px !important;
            height: 70px !important;
            line-height: 70px !important;
        }

        .content_c {
            width: 700px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
        .submit{
            width: 730px !important;
        }
        .butn{
            width: 380px !important;
        }
    }

    .register {
        width: 100%;
        height: 100%;
    }

    .top {
        width: 100%;
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-size: 30px;
        background: #29726C;
        color: #fff;
    }

    .content {
        width: 100vw;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_c {
        width: 730px;
        height: 600px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important; */
    }

    .form {
        border-radius: 8px;
        display: flex;
        align-content: flex-start;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        /* width: 240px; */
        /* height: 37px; */
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form ::v-deep .el-form-item{
        /* margin-bottom: 20px !important; */
        height: 40px !important;
        margin-top: 0px !important;
    }
    .jigouReg {
        width: 100%;
        height: 40px;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .Reg {
        margin-left: 20px;
        color: #212121;
        font-size: 18px
    }

    .have {
        margin-right: 20px;
        color: #707070;
        font-size: 14px;
        cursor: pointer;
    }
    /* 提交按钮 */
    .submit{
        width:730px ;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .butn{
        width:380px;
        background: #1AAD19;
        height: 40px;
        color: #fff;
        border: none;
        cursor: pointer;
    }
</style>
